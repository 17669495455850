import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'retryable-image',
  templateUrl: './retryable-image.component.html',
  styleUrls: ['./retryable-image.component.scss']
})
export class RetryableImageComponent {

  source?: string;

  @Input() set src(data: string | undefined) {
    this.source = data;
    if (this.timeout) clearTimeout(this.timeout);
  }

  @Input() alt = 'image';
  @Input() placeholder = '/assets/loading.gif';

  @Output() imageLoadAttempted = new EventEmitter<void>();

  timeout?: number;

  waitAndReloadImage(event: ErrorEvent, panelImage: HTMLImageElement) {
    const originalSrc = panelImage.src;

    const attempt = parseInt(panelImage.getAttribute('data-retry') as string, 10);
    const maxAttempts = parseInt(panelImage.getAttribute('data-max-retry') || '5', 10);
    const interval = parseInt(panelImage.getAttribute('data-retry-interval') || '1000', 10);

    this.imageLoadAttempted.emit();

    if (attempt !== maxAttempts) {
      panelImage.setAttribute('data-retry', String(attempt + 1));

      panelImage.src = panelImage.getAttribute('data-placeholder') as string;

      this.timeout = (setTimeout(function () {
        panelImage.src = originalSrc;
      }, interval) as unknown) as number;
    } else {
      panelImage.src = panelImage.getAttribute('data-placeholder') as string;
    }
  }
}
