import { MatDialogRef } from '@angular/material/dialog';
import { ElementRef } from '@angular/core';
import { ProgressRef, ProgressService } from '../../../service/progress.service';

export abstract class BaseModalComponent {

  protected abstract progressContainer: ElementRef;

  private dataLoadingProgress!: ProgressRef | null;

  private _inProgress = false;

  protected constructor(private dialogRef: MatDialogRef<unknown>,
                        private progressService: ProgressService) {
  }

  public abstract proceedAllowed(): boolean;

  public abstract proceed(): void;

  get inProgress(): boolean {
    return this._inProgress;
  }

  stopProgress() {
    this._inProgress = false;
    this.dialogRef.disableClose = false;

    if (this.dataLoadingProgress) {
      this.progressService.detach(this.dataLoadingProgress);
    }
  }

  startProgress() {
    this._inProgress = true;
    this.dialogRef.disableClose = true;

    this.dataLoadingProgress = this.progressService.showProgress(this.progressContainer);
  }

  closeModal(data?: unknown): void {
    this.dialogRef.close(data);
  }
}
