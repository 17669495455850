export interface Environment {
  production: boolean;
  env: 'local' | 'dev' | 'stage' | 'prod';
  api: {
    host: string;
    integration: string;
  };
  apiKey: Record<string, string>;
  auth: {
    region: string;
    pool_id: string;
    client_id: string;
  };
  UI: {
    DEBOUNCE_TIME: number;
  };
}

export const baseEnvironment = {
  production: false,
  UI: {
    DEBOUNCE_TIME: 300, // ms
  },
};
