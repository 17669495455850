import { v4 as uuid } from 'uuid';

export class DataUtil {

  public static generateId(): string {
    return uuid();
  }

  public static generateTimeBasedId(): string {
    return (new Date().getTime() / 1000).toFixed(0)
  }

  public static getTimestamp(): string {
    return new Date().getTime().toString();
  }

  public static capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  public static capitalizeFirstLettersForSentence(value = '', clear = false): string {
    if (clear) value = value.replaceAll('_', ' ');
    return value.split(' ').map((c: string) => DataUtil.capitalizeFirstLetter(c)).join(' ');
  }

  public static getFileExtension(file = ''): string {
    return file.includes('.') ? file.split('.').pop() || '' : '';
  }

  public static roundToDecimal(value: number, places = 2) {
    return Math.round((value + Number.EPSILON) * 10 ** places) / 10 ** places;
  }
}
