<app-no-content *ngIf="!inProgress && !hasPermission"
                [compact]="true"
                title="Access to this content is not allowed"
                details="If you think that this is mistake, please contact support.">
  <br/>

  <div *ngIf="!inProgress && (!session || !session.isValid())" class="footer center-align">
    <app-link [routerLink]="signInUrl"
              label="Sign In"
              class="link"></app-link>
  </div>
</app-no-content>

<div *ngIf="inProgress" class="content">
  <mat-spinner color="primary" diameter="40" class="center-middle"></mat-spinner>
</div>

<ng-content *ngIf="hasPermission"></ng-content>
