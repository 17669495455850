import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-mobile-aware',
  template: '',
  styles: ['']
})
export class MobileAwareComponent implements OnInit, OnDestroy {

  private breakpointChangeSubscription: Subscription | undefined;
  mobileView = false;
  smallScreenView = false;
  largeScreenView = false;

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpointChangeSubscription = this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.Small, Breakpoints.Large])
      .subscribe((state: BreakpointState) => {
        this.mobileView = state.breakpoints[Breakpoints.HandsetPortrait];
        this.smallScreenView = state.breakpoints[Breakpoints.Small];
        this.largeScreenView = state.breakpoints[Breakpoints.Large];
      });
  }

  ngOnDestroy(): void {
    if (this.breakpointChangeSubscription) {
      this.breakpointChangeSubscription.unsubscribe();
    }
  }

}
