import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceAll'
})
export class ReplaceAllPipe implements PipeTransform {

  transform(value: string, pattern = '', replacement = ''): string {
    if (typeof value !== 'string') return value;
    return value.replaceAll(pattern, replacement);
  }
}
