<mat-form-field
  class="full-width form-input"
  [class.small-text]="compact"
>
  <mat-label>{{ label }}</mat-label>

  <mat-select
    [formControl]="control"
    [required]="required"
  >
    <mat-option
      *ngFor="let option of options$ | async"
      [value]="option.value"
    >{{option.label}}</mat-option>
  </mat-select>

</mat-form-field>
