const rePropName = RegExp(
// Match anything that isn't a dot or bracket.
'[^.[\\]]+' + '|' +
// Or match property names within brackets.
'\\[(?:' +
// Match a non-string expression.
'([^"\'][^[]*)' + '|' +
// Or match strings (supports escaping characters).
'(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' + ')\\]' + '|' +
// Or match "" as the space between consecutive dots or empty brackets.
'(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))', 'g');
function castPath(value) {
  var _a, _b, _c;
  const result = [];
  let match;
  while (match = rePropName.exec(value)) {
    result.push((_c = (_a = match[3]) !== null && _a !== void 0 ? _a : (_b = match[1]) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : match[0]);
  }
  return result;
}
export function getProp(obj, path, defaultValue) {
  if (path in obj) {
    const value = obj[path];
    return value === undefined ? defaultValue : value;
  }
  const processedPath = Array.isArray(path) ? path : castPath(path, obj);
  let currentValue = obj;
  for (const key of processedPath) {
    currentValue = currentValue === null || currentValue === void 0 ? void 0 : currentValue[key];
    if (currentValue === undefined) return defaultValue;
  }
  return currentValue;
  // const value = obj[path];
  // return value === undefined ? defaultValue : value;
}
function propertyPathToString(path) {
  if (typeof path === 'string') return path.split('.');
  return path;
}
export function setProp(obj, path, value) {
  const pathArray = propertyPathToString(path);
  const [key, ...restPath] = pathArray;
  return Object.assign(Object.assign({}, obj), {
    [key]: pathArray.length > 1 ? setProp(obj[key] || {}, restPath, value) : value
  });
}
export function unsetProp(obj, path) {
  const pathArray = propertyPathToString(path);
  const [key, ...restPath] = pathArray;
  // This will never be hit in the current code because unwind does the check before calling unsetProp
  /* c8 ignore next */
  if (typeof obj[key] !== 'object') {
    return obj;
  }
  if (pathArray.length === 1) {
    return Object.keys(obj).filter(prop => prop !== key).reduce((acc, prop) => Object.assign(Object.assign({}, acc), {
      [prop]: obj[prop]
    }), {});
  }
  return Object.keys(obj).reduce((acc, prop) => Object.assign(Object.assign({}, acc), {
    [prop]: prop !== key ? obj[prop] : unsetProp(obj[key], restPath)
  }), {});
}
export function flattenReducer(acc, arr) {
  try {
    // This is faster but susceptible to `RangeError: Maximum call stack size exceeded`
    Array.isArray(arr) ? acc.push(...arr) : acc.push(arr);
    return acc;
  } catch (err) {
    // Fallback to a slower but safer option
    return acc.concat(arr);
  }
}
