import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role } from '@common/model/auth/role';
import { Session } from '@common/model/auth/session';
import { AuthService } from '@common/service/auth.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'permission-checker',
  templateUrl: './permission-checker.component.html',
  styleUrls: ['./permission-checker.component.scss']
})
export class PermissionCheckerComponent implements OnInit {

  @Input() roles!: Role[];

  @Output() contentInit = new EventEmitter<void>();
  @Output() rolesLoaded = new EventEmitter<Role[]>();

  session?: Session;

  inProgress = true;

  constructor(private authService: AuthService) {
  }

  get signInUrl() {
    return '/auth/sign-in';
  }

  ngOnInit() {
    this.authService.getSession().subscribe({
      next: session => {
        this.session = session;

        if (this.hasPermission) {
          this.rolesLoaded.emit(this.session.roles || []);
          this.contentInit.emit();
        }
      },
      complete: () => {
        this.inProgress = false;
      }
    });
  }

  get hasPermission(): boolean {
    return !!this.session
      && this.session.isValid()
      && this.roles.map(role => this.session?.hasPermission(role) || false).reduce((r1, r2) => r1 || r2);
  }
}
