export { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

export enum ImageCropperOutputFormat {
  jpeg = 'jpeg',
}

export enum ImageCropperObjectType {
  file = 'file',
  url = 'url',
}
