import { baseEnvironment, Environment } from './environment.base';

export const environment: Environment = {
  ...baseEnvironment,

  env: 'dev',

  api: {
    host: 'https://api.dev.b2b.rvprintfactory.com',
    integration: 'https://dev.api.rvprintfactory.com',
  },

  apiKey: {
    GOOGLE_API_KEY: 'AIzaSyDePPZMgen7-MFoJc74K-PbwU7z_sVDDTo',
  },

  auth: {
    region: 'eu-central-1',
    pool_id: 'eu-central-1_hCwlGWLwB',
    client_id: 't5rjbdf2nj8jvcp5knluvta40'
  },
};
