import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@common/service/auth.service';
import { switchMap } from 'rxjs/operators';
import { environment } from '@environment';

export enum ApiContext {
  b2b = 'b2b',
  bucket = 'bucket',
  integration = 'integration',
  google = 'google',
}

export const API_CONTEXT = new HttpContextToken<ApiContext>(() => ApiContext.b2b);
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  private static isBucketUrl(request: HttpRequest<unknown>): boolean {
    return request.url.search('s3.*amazonaws.com') !== -1;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (AuthInterceptor.isBucketUrl(request)) {
      return next.handle(request);
    }

    switch (request.context.get(API_CONTEXT)) {
      case ApiContext.integration:
        return this.authService
          .getSession()
          .pipe(switchMap(session => {

            if (session && session.isValid()) {
              request = request.clone({
                setHeaders: {
                  'x-api-key': session.apiKey || '',
                }
              });
            }

            return next.handle(request);
          }));

      case ApiContext.google:
        return next.handle(request.clone({
          params: request.params.append('key', environment.apiKey['GOOGLE_API_KEY'])
        }));

      case ApiContext.bucket:
        return next.handle(request);

      case ApiContext.b2b:
      default:
        return this.authService
          .getSession()
          .pipe(switchMap(session => {

            if (session && session.isValid()) {
              const integrationCall = request.url.includes(environment.api.integration);

              request = request.clone({
                setHeaders: {
                  ...integrationCall && { 'x-api-key': session.apiKey || '' },
                  ...!integrationCall && { Authorization: session.token ? `Bearer ${session.token}` : '' || '' }
                }
              });
            }

            return next.handle(request);
          }));
    }
  }

}
