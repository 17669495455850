<div class="file-upload-container"
     (click)="fileInput.click()"
     fileDragDrop
     (fileDropped)="dropFile($event)">
  <input hidden
         type="file"
         #fileInput
         [accept]="fileTypes"
         (change)="selectFile($event)">
  <mat-icon color="accent" class="upload-icon">cloud_upload</mat-icon>
  <div>Drag and drop images here or click to browse from your device</div>
  <div *ngIf="acceptTypes.length" class="file-types-note text-disabled-color">Allowed file types: {{acceptTypes}}</div>
</div>

<div *ngIf="showSelected">
  <div class="file-upload-selected-file" *ngFor="let file of files; let i= index">
    <p class="selected-file"> {{ file?.name }} </p>

    <app-button icon="delete"
                [secondary]="true"
                [destructive]="true"
                class="delete-file"
                (clicked)="deleteAttachment(i)"></app-button>
  </div>

  <div *ngIf="!files.length" class="file-upload-selected-file text-disabled-color">
    <div class="center-middle">No files selected</div>
  </div>
</div>
