<ng-container *ngIf="{
  loadingState:  loadingState$ | async,
  cropInProgress:  cropInProgress$ | async
  } as obs">

  <div
    class="loading"
    *ngIf="obs.loadingState === LoadingState.LOADING || obs.cropInProgress"
  >
    <mat-spinner
      [diameter]="20"
      class="center"
      color="primary"
    ></mat-spinner>
  </div>

  <ng-container *ngIf="obs.loadingState === LoadingState.ERROR">Wrong file type was selected (only png, gif and jpg are
    allowed)</ng-container>

  <ng-container [ngSwitch]="objectType">
    <image-cropper
      *ngSwitchCase="ImageCropperObjectType.file"
      [imageFile]="$any(imageObject)"
      [imageQuality]="imageQuality"
      [initialStepSize]="initialStepSize"
      [maintainAspectRatio]="maintainAspectRatio"
      [aspectRatio]="aspectRatio"
      [cropperMinHeight]="cropperMinHeight"
      [cropperMinWidth]="cropperMinWidth"
      [format]="format"
      [output]="output"
      (imageLoaded)="imageLoadedHandler($event)"
      (loadImageFailed)="imageLoadingFailedHandler()"
      (cropperReady)="cropperReadyHandler()"
      (startCropImage)="startCropImageHandler()"
      (imageCropped)="finishCropImageHandler($event)"
    ></image-cropper>

    <image-cropper
      *ngSwitchCase="ImageCropperObjectType.url"
      [imageURL]="$any(imageObject)"
      [imageQuality]="imageQuality"
      [initialStepSize]="initialStepSize"
      [maintainAspectRatio]="maintainAspectRatio"
      [aspectRatio]="aspectRatio"
      [cropperMinHeight]="cropperMinHeight"
      [cropperMinWidth]="cropperMinWidth"
      [format]="format"
      [output]="output"
      (imageLoaded)="imageLoadedHandler($event)"
      (loadImageFailed)="imageLoadingFailedHandler()"
      (cropperReady)="cropperReadyHandler()"
      (startCropImage)="startCropImageHandler()"
      (imageCropped)="finishCropImageHandler($event)"
    ></image-cropper>

    <ng-container *ngSwitchDefault>Invalid image object type</ng-container>
  </ng-container>
</ng-container>
