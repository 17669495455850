export class User {

  public id: string;

  public email!: string;

  public username?: string;

  public firstName?: string;

  public lastName?: string;

  public enterprise?: boolean;

  constructor(id: string) {
    this.id = id;
  }

  public getAvatarLetters(): string {
    let avatarLetters = (this.firstName ? this.firstName[0] : '') + (this.lastName ? this.lastName[0] : '');

    if (!avatarLetters) {
      avatarLetters = 'U';
    }

    return avatarLetters.toUpperCase();
  }
}
