import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ProgressService } from '@common/service/progress.service';
import { NotificationHandler } from '@common/helper/notification.handler';

export interface ConfirmationData<T = unknown> {
  title?: string;
  content?: string;
  details?: string;
  detailsTitle?: string;
  action?: string;
  cancel_action?: string;
  destructive?: boolean;
  item?: T;
}

export interface ConfirmationContext<T> {
  data: T;
  modal: ConfirmationModalComponent<T>;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent<T> extends BaseModalComponent {

  title: string;
  content: string;
  details?: string;
  detailsTitle?: string;
  action: string;
  cancelAction: string;
  destructive = false;

  @Output() actionConfirmed = new EventEmitter<ConfirmationContext<T>>();

  @ViewChild('dialogContent', {static: true}) protected progressContainer!: ElementRef;

  private readonly data: ConfirmationData<T>;

  constructor(dialogRef: MatDialogRef<ConfirmationModalComponent<T>>,
              progressService: ProgressService,
              @Inject(MAT_DIALOG_DATA) data: ConfirmationData<T>) {
    super(dialogRef, progressService);

    this.title = data.title || 'Confirmation';
    this.content = data.content || 'Do you want to proceed?';
    this.details = data.details;
    this.detailsTitle = data.detailsTitle;
    this.action = data.action || 'Proceed';
    this.cancelAction = data.cancel_action || 'Cancel';
    this.destructive = data.destructive || false;

    this.data = data;
  }

  proceed(): void {
    this.startProgress();

    this.actionConfirmed.emit({
      data: this.data.item as T,
      modal: this
    });
  }

  proceedAllowed(): boolean {
    return true;
  }

  public proceedHandled(error?: string, details?: string) {
    if (error) {
      NotificationHandler.handleError(error, details);
      this.stopProgress();
    } else {
      this.stopProgress();
      this.closeModal();
    }
  }
}
