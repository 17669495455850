<mat-form-field class="form-input full-width">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipGrid aria-label="Item selection">
    <mat-chip-row *ngFor="let item of selectedItems"
                  [removable]="removable"
                  (removed)="remove(item)">
      {{ displayWith(item) }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>

  <input [placeholder]="placeholder" #input
         [readonly]="disabled"
         [formControl]="control"
         [matAutocomplete]="auto"
         [matChipInputFor]="chipGrid"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         (matChipInputTokenEnd)="add($event)">

  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayWith"
                    (optionSelected)="optionSelected($event)">
    <mat-option *ngIf="dataLoading" class="progress-indicator">
      <mat-spinner diameter="30" color="primary"></mat-spinner>
    </mat-option>

    <ng-container *ngIf="!dataLoading">
      <mat-option *ngFor="let item of foundItems" [value]="item" [class.option-small-text]="fitText">
        {{ displayWith(item) }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
