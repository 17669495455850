<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content #dialogContent>
  <p class="mat-body confirmation-content">
    {{content}}
  </p>
  <p *ngIf="details">
    <span *ngIf="detailsTitle" class="detail-title-label text-secondary-color">{{detailsTitle}}:&thinsp;</span>
    <span [class.mat-body-2]="detailsTitle">{{details}}</span>
  </p>
</div>
<div mat-dialog-actions>
  <app-button [disabled]="inProgress"
              (clicked)="closeModal()"
              [secondary]="true"
              [label]="cancelAction"></app-button>
  <span class="btn-spacer"></span>
  <app-button [destructive]="destructive"
              [disabled]="inProgress"
              (clicked)="proceed()"
              [label]="action"></app-button>
</div>
