import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationHandler } from '@app/common/helper/notification.handler';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() acceptTypes: string[] = [];
  @Input() multiSelect = false;
  @Input() showSelected = true;

  @Output() fileSelected = new EventEmitter<File[]>();

  get fileTypes(): string {
    return this.acceptTypes.map(e => `.${e}`).join(',')
  }

  files: File[] = [];

  dropFile(files: File[]) {
    this.handleFileInternal(files);
  }

  selectFile(event: Event) {
    if (event.target && 'files' in event.target) {
      this.handleFileInternal(event.target['files'] as File[] || [], event);
    }
  }

  private clearFileInput(event: Event) {
    if (event?.target as HTMLInputElement) {
      (event.target as HTMLInputElement).value = '';
    }
  }

  private handleFileInternal(files: File[], event?: Event) {
    for (const file of files) {
      if (this.acceptTypes.length) {
        const formtAllowed = this.acceptTypes.some(type => file.name.endsWith(type));
        if (!formtAllowed) {
          NotificationHandler.handleError(`File ${file.name} has unsupported image format. Supported formats: ${this.acceptTypes}`);
          break;
        }
      }

      if (!this.multiSelect && this.files.length >= 1) {
        this.files = [];
        this.files.push(file);
      } else {
        this.files.push(file);
      }

      this.fileSelected.emit(this.files);
    }
    if (event) this.clearFileInput(event);
  }

  deleteAttachment(index: number) {
    this.files.splice(index, 1);
    this.fileSelected.emit(this.files);
  }
}
