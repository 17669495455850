import { Parser } from '@json2csv/plainjs';
import { flatten, FlattenOptions, Transform } from '@json2csv/transforms';
import { NotificationHandler } from '@app/common/helper/notification.handler';

type CSV = string;

interface JsonToCsvOptions {
  flatten?: {
    objects?: boolean;
    arrays?: boolean;
    separator?: '.' | '_' | '[]'; // default "."
  };
}

export class CSVUtil {
  public static async getFileContent(file: File) {
    const url = URL.createObjectURL(file);
    const response = await fetch(url);
    URL.revokeObjectURL(url)

    return response.text();
  }

  public static jsonToCsv(data: object, options?: JsonToCsvOptions): CSV {
    try {
      const replaceDefaultSeparator = (obj: { [key: string]: unknown; }) => Object.keys(obj).reduce((acc, field) => ({
        ...acc,
        [
          field
            .replace(/\.(\d+)\./g, '[$1].')
            .replace(/\.(\d+)/g, '[$1]')
        ]: obj[field]
      }), {});

      const flattenConfig: FlattenOptions = {
        objects: Boolean(options?.flatten?.objects),
        arrays: Boolean(options?.flatten?.arrays),
        separator: options?.flatten?.separator && options.flatten.separator !== '[]' ? options.flatten.separator : '.',
      };

      const transforms = [flatten(flattenConfig)];

      if (options?.flatten?.separator === '[]') {
        transforms.push(replaceDefaultSeparator as Transform<object, object>);
      }

      const parser = new Parser({ transforms } as never);

      return parser.parse(data);
    } catch (err) {
      console.error(err);
      NotificationHandler.handleError((err as Error)?.message || 'CSV file parsing error');
      throw err;
    }
  }
}
