import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@app/helper';

@Pipe({
  standalone: true,
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  transform(text: string | undefined, qty: number): string {
    return StringUtil.pluralize(text || '', qty)
  }

}
