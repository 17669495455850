<a *ngIf="!item.external"
   mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   (click)="onItemSelected()"
   [routerLink]="item.path ? item.path : undefined"
   [routerLinkActive]="item.path ? 'active' : ''"
   [routerLinkActiveOptions]="{exact:true}"
   class="menu-item no-focus">
  <mat-icon class="icon">{{item.icon}}</mat-icon>
  {{item.label}}
  <span class="fill-space"></span>
  <span class="flexible-item" *ngIf="item.children && item.children.length">
    <span class="flexible-item"></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>

<a *ngIf="item.external"
   mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   target="_blank"
   [href]="item.path"
   class="menu-item no-focus">
  <mat-icon class="icon">{{item.icon}}</mat-icon>
  {{item.label}}
</a>

<div *ngIf="!item.external && (shouldBeExpanded || expanded) && !!item.children?.length">
  <menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1"
                  [currentUrl]="currentUrl"></menu-list-item>
</div>
