import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ImageCropperModule } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

import { LoadingState } from '@app/common/model';
import { ImageCroppedEvent, LoadedImage, ImageCropperOutputFormat, ImageCropperObjectType } from './image-cropper.model';

@Component({
  standalone: true,
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    ImageCropperModule,
    AsyncPipe,
    MatProgressSpinnerModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class ImageCropperComponent {
  readonly LoadingState = LoadingState;
  readonly ImageCropperObjectType = ImageCropperObjectType;

  protected imageObject?: File | string;
  @Input() set image(image: File | string) {
    this.imageObject = image;
    this.loadingState$.next(LoadingState.LOADING);
  }
  @Input() objectType?: ImageCropperObjectType;
  @Input() imageQuality = 100;
  @Input() initialStepSize = 1;
  @Input() aspectRatio = 1;
  @Input() maintainAspectRatio = true;
  @Input() cropperMinWidth = 0;
  @Input() cropperMinHeight = 0;
  @Input() format = ImageCropperOutputFormat.jpeg;
  @Input() output: 'blob' | 'base64' = 'blob';

  readonly loadingState$ = new BehaviorSubject<LoadingState>(LoadingState.INIT);
  readonly cropInProgress$ = new BehaviorSubject(false);

  @Output() startCropImage = new EventEmitter<void>();
  @Output() finishCropImage = new EventEmitter<ImageCroppedEvent>();
  @Output() imageLoaded = new EventEmitter<LoadedImage>();
  @Output() loadImageFailed = new EventEmitter<void>();

  protected startCropImageHandler(): void {
    this.cropInProgress$.next(true);
    this.startCropImage.emit();
  }

  protected finishCropImageHandler(evt: ImageCroppedEvent): void {
    this.cropInProgress$.next(false);
    this.finishCropImage.emit(evt);
  }

  protected imageLoadedHandler(evt: LoadedImage): void {
    this.imageLoaded.emit(evt);
  }

  protected imageLoadingFailedHandler(): void {
    this.loadImageFailed.emit();
    this.loadingState$.next(LoadingState.ERROR);
    this.cropInProgress$.next(false);
  }

  protected cropperReadyHandler(): void {
    this.loadingState$.next(LoadingState.LOADED);
  }
}
