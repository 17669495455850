import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

interface Option {
  label: string;
  value: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent {

  protected options$ = new BehaviorSubject<Option[]>([]);

  @Input() control!: FormControl;
  @Input() label = 'Select';
  @Input() placeholder: string | undefined;
  @Input() set options(opts: string[] | Option[] | null | undefined) {
    if (opts) {
      this.buildOptions(opts);
    }
  }
  @Input() required = true;
  @Input() compact = false;

  private buildOptions(opts: string[] | Option[]): void {
    if ((opts as string[])?.every(opt => typeof opt === 'string')) {
      this.options$.next((opts as string[]).map(opt => ({ label: opt, value: opt })));
    } else {
      this.options$.next(opts as Option[]);
    }
  }
}
