<mat-form-field class="full-width">
  <mat-label>{{label}}</mat-label>
  <mat-select [disabled]="disabled"
              (selectionChange)="itemSelected($event)"
              [compareWith]="compareWith"
              [required]="required"
              [value]="selectedItem">
    <mat-option *ngFor="let item of items" [value]="item" [disabled]="checkItemDisabled(item)">{{displayWith(item)}}</mat-option>
  </mat-select>
  <mat-spinner *ngIf="!items" diameter="15" color="primary" matSuffix></mat-spinner>
</mat-form-field>
