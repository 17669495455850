import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'items-multi-select',
  templateUrl: './items-multi-select.component.html',
  styleUrls: ['./items-multi-select.component.scss']
})
export class ItemsMultiSelectComponent<T> implements OnInit {

  private static readonly MAX_SEARCH_STRING_LENGTH = 64;

  @Input() label = 'Input';
  @Input() hint: string | undefined;
  @Input() placeholder = 'Select items...';
  @Input() fitText = false;
  @Input() required = true;
  @Input() allowCustomValue = false;

  @Input() selectedItems: T[] = [];

  @Input() displayWith!: (item?: T) => string;
  @Input() searchWith!: (query: string) => Observable<T[]>;
  @Input() addWith?: (value: string) => T | undefined;

  dataLoading = false;

  foundItems: T[] = [];
  @Input() clearAfterSelect = true;

  @Output() optionChanged = new EventEmitter<T[]>();

  @ViewChild('input', {static: true}) searchElement!: ElementRef;

  @Input() selectable = true;
  @Input() removable = true;
  @Input() limit = 100;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  control = new FormControl();

  formGroup = new FormGroup({
    control: this.control,
  });

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.dataLoading = true;
        }),
        switchMap(value => this.search(value as string)
          .pipe(
            map(items => {
              if (this.allowCustomValue && this.control.valid && value && !items.includes(value )) {
                items.push(value);
              }

              return items;
            }),
            finalize(() => this.dataLoading = false),
          )
        )
      )
      .subscribe((items: T[]) => {
        this.foundItems = items || [];
      });
  }

  private search(query: string): Observable<T[]> {
    return query && query.length > 0
      ? this.searchWith(query.trim().substring(0, ItemsMultiSelectComponent.MAX_SEARCH_STRING_LENGTH))
      : of([]);
  }

  add(event: MatChipInputEvent): void {
    if (this.disabled) {
      return;
    }

    const value = event.value && this.addWith ? this.addWith(event.value) : undefined;

    if (value && !this.selectedItems.includes(value) && (this.addWith || this.foundItems.includes(value))) {
      this.valueSelected(value);
    }
  }

  remove(remove: T) {
    this.selectedItems = this.selectedItems.filter(item => JSON.stringify(item) !== JSON.stringify(remove));
    this.optionChanged.emit(this.selectedItems);
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.valueSelected(<T>event.option.value);
  }

  private valueSelected(value: T) {
    if (this.clearAfterSelect) {
      this.control.setValue(null);
    }

    if (this.disabled) {
      return;
    }

    this.renderer.setProperty(this.searchElement.nativeElement, 'value', '');
    this.selectedItems.push(value);
    this.optionChanged.emit(this.selectedItems);
  }

  get disabled() {
    return this.selectedItems.length >= this.limit;
  }
}
