import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationHandler } from '../../helper/notification.handler';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent<T> implements OnInit, OnChanges {

  @Input() label!: string;
  @Input() disabled = false;
  @Input() required = false;

  @Input() compareWith: (i1?: T, i2?: T) => boolean = this.comparator;
  @Input() displayWith!: (item: T) => string;
  @Input() isItemDisabled?: (item: T) => boolean;
  @Input() loadWith!: () => Observable<T[]>;
  @Input() selectedItem?: T | null;

  @Output() selected = new EventEmitter<T>();
  @Output() loaded = new EventEmitter<T[]>();

  items!: T[] | null;

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadWith']?.currentValue) {
      this.reloadData();
    }
  }

  public reloadData(): void {
    this.loadItems();
  }

  private loadItems() {
    this.items = null;

    this.loadWith().subscribe({
      next: items => {
        this.items = items;

        this.loaded.emit(items);

        if (this.selectedItem) {
          const selected = this.items.filter(item => this.compareWith(item, this.selectedItem || undefined));
          if (selected && selected.length > 0) {
            this.selected.emit(selected[0]);
          }
        }
      },
      error: error => NotificationHandler.handleError('Items fetch has failed', error)
    });
  }

  private comparator(c1?: unknown, c2?: unknown): boolean {
    return !!c1 && !!c2 && c1 === c2;
  }

  itemSelected(change: MatSelectChange) {
    this.selected.emit(change.value as T);
  }

  checkItemDisabled(item: T): boolean {
    if (this.isItemDisabled) return this.isItemDisabled(item);
    return false;
  }

}
