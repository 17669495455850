import { DataUtil } from './data.util';

export class ImageUtil {

  public static async getImageFileDimensions(file: File): Promise<Pick<ImageBitmap, 'height' | 'width'>> {
    const bmp = await createImageBitmap(file);
    const dimensions = { width: bmp.width, height: bmp.height };
    bmp.close();

    return dimensions;
  }

  public static async getImageFileRatio(file: File): Promise<number> {
    const dimensions = await this.getImageFileDimensions(file);

    return DataUtil.roundToDecimal(dimensions.width / dimensions.height, 2);
  }
}
